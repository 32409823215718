<template>
  <div id="reset-password">
    <div class="form" @submit.prevent @submit="reset()">
      <v-form ref="resetForm" v-model="valid">
        <h1>{{ $t('auth.reset.title') }}</h1>

        <v-text-field
          color="primary"
          v-model="resetForm.email"
          :rules="emailRules"
          :label="$t('auth.reset.email')"
          required
        />

        <div class="controls">
          <v-btn
            class="controls__primary"
            color="primary"
            :disabled="!valid || isSending"
            :loading="isSending"
            outlined
            type="submit"
            >{{ $t('auth.reset.submit') }}</v-btn
          >

          <v-btn color="primary" text data-test-id="login" to="/login">{{
            $t('auth.reset.login')
          }}</v-btn>

          <v-btn color="primary" text data-test-id="register" to="/register">{{
            $t('auth.reset.create')
          }}</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import validatorsHelper from '@/helpers/forms/validators.helper';
import { APP_PASSWORD_MODULE, RESET } from '@/stores/umanize-app/actions/password/password.actions';

export default {
  name: 'ResetPassword',
  data: () => ({
    resetForm: {
      email: '',
    },
    emailRules: [validatorsHelper.EMAIL.REQUIRED, validatorsHelper.EMAIL.FORMAT],
    valid: false,
  }),
  computed: {
    ...mapGetters(APP_PASSWORD_MODULE, ['error', 'isSending']),
  },
  methods: {
    ...mapActions(APP_PASSWORD_MODULE, [RESET]),
    async reset() {
      await this[RESET](this.resetForm);
      this.$refs.resetForm.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

#reset-password {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: auto;
}

.form {
  padding: 5rem 0;
  width: 80vw;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__primary {
    margin: 1rem;
  }
}

@include breakpoint(small) {
  .form {
    width: 30rem;
  }
}
</style>
