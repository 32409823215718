<template>
  <div id="login">
    <div class="login">
      <img class="login__bg-top" src="~@assets/backgrounds/login-top.png" />
      <img class="login__logo" src="~@assets/logos/logo_with_text.png" alt="logo" />

      <login-form class="login__form" :error="error" :is-loading="isSending" @login="login" />
      <img class="login__bg-bottom" src="~@assets/backgrounds/login-bottom.png" />

      <img class="login__bg-bottom--mobile" src="~@assets/backgrounds/login-bottom-mobile.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SHARED_AUTH_MODULE, LOGIN } from '@/stores/shared/actions/auth/auth.actions';
import LoginForm from '@/components/login-form/LoginForm.vue';
import { navigateToDashBoard } from '@/navigation';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  computed: {
    ...mapGetters(SHARED_AUTH_MODULE, ['error', 'isSending']),
  },
  methods: {
    ...mapActions(SHARED_AUTH_MODULE, [LOGIN]),
    async login(loginForm) {
      await this[LOGIN](loginForm);
      if (!this.error) {
        const redirect = this.$router.currentRoute?.query?.redirect;
        if (redirect) {
          await this.$router.push(redirect).catch((failure) => {
            console.debug(failure);
          });
        } else {
          await navigateToDashBoard(this.$router, this.$store);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

#login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__form {
    z-index: 1;
    width: 80vw;
    margin-bottom: 125px;
  }

  &__logo {
    z-index: 1;
    max-width: 250px;
  }

  &__bg-top {
    display: none;
    position: absolute;
    top: -225px;
    right: -25px;
    max-width: 1150px;
    width: 100%;
    transform: matrix(0.97, 0.22, -0.22, 0.97, 0, 0);
  }

  &__bg-bottom {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;

    &--mobile {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

@include breakpoint(medium) {
  #login {
    justify-content: center;
  }

  .login {
    &__form {
      width: 30rem;
    }

    &__logo {
      max-width: 350px;
    }

    &__bg-top {
      display: block;
    }

    &__bg-bottom {
      display: block;

      &--mobile {
        display: none;
      }
    }
  }
}
</style>
